import React from 'react';
import Footer from './footer';
import Navbar from './navbar'
const FAQ = () => {
  return (
    <> <Navbar/>
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Amberspace - Frequently Asked Questions (FAQ)</h1>

      <h2>1. What is Amberspace?</h2>
      <p>Amberspace is an online electronics store based in Kenya, offering a wide range of electronic devices and accessories.</p>

      <h2>2. What payment methods do you accept?</h2>
      <p>We currently accept payments via M-Pesa, the most popular mobile payment method in Kenya. You will receive instructions on how to complete the payment during the checkout process.</p>

      <h2>3. How long does shipping take?</h2>
      <p>Shipping times vary depending on your location. Typically, orders within Kenya take between 2-5 business days. You will receive an estimated delivery time during checkout.</p>

      <h2>4. Do you offer international shipping?</h2>
      <p>At the moment, Amberspace only ships within Kenya. We are working to expand our shipping options in the future.</p>

      <h2>5. How do I return an item?</h2>
      <p>If you are not satisfied with your purchase, you can return the item within [X] days of the purchase date. The product must be in its original condition and packaging. To initiate a return, please contact our customer service team at [insert email].</p>

      <h2>6. What is your warranty policy?</h2>
      <p>Our products come with warranties, where applicable, as stated on the product pages. Please refer to each product’s specific warranty details before purchasing.</p>

      <h2>7. How do I contact customer support?</h2>
      <p>If you have any questions or need assistance, you can call us at +254101165327. Our customer support team is available Monday to Friday from 9:00 AM to 6:00 PM.</p>

      {/* <h2>8. Can I track my order?</h2>
      <p>Yes, once your order is shipped, you will receive a tracking number via email or SMS, which you can use to track your package.</p> */}

      <h2>9. What happens if my order is damaged or missing?</h2>
      <p>If your order arrives damaged or items are missing, please contact our customer service team within 48 hours of delivery. We will work to resolve the issue as quickly as possible.</p>

      <h2>10. Do you offer discounts or promotions?</h2>
      <p>Yes! Amberspace often runs special promotions and discounts. Sign up for our newsletter or follow us on social media to stay updated on the latest offers.</p>

    </div>
    <Footer/>
    </>
   
  );
};

export default FAQ;
