import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'white', py: 6 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Policies
            </Typography>
            <Box>
              <Link href="/privacy-policy" color="inherit" display="block" sx={{ mb: 1 }}>
                Privacy Policy
              </Link>
              <Link href="/terms" color="inherit" display="block" sx={{ mb: 1 }}>
                Terms of Service
              </Link>
              <Link href="/return-policy" color="inherit" display="block">
                Return Policy
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <Box>
              <Link href="/contact-us" color="inherit" display="block" sx={{ mb: 1 }}>
                Contact Us
              </Link>
              {/* <Link href="/support" color="inherit" display="block" sx={{ mb: 1 }}>
                Customer Support
              </Link>*/}
              <Link href="/faq" color="inherit" display="block">
                FAQ
              </Link> 
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Categories
            </Typography>
            <Box>
              <Link href="/category" color="inherit" display="block" sx={{ mb: 1 }}>
                Laptops
              </Link>
              <Link href="/category" color="inherit" display="block" sx={{ mb: 1 }}>
                Monitors
              </Link>
              <Link href="/category" color="inherit" display="block" sx={{ mb: 1 }}>
                Printers
              </Link>
              <Link href="/category" color="inherit" display="block">
                Tablets
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" align="center" sx={{ mt: 4 }}>
          © {new Date().getFullYear()} Amberspace Kenya. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;