import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { IconButton, Badge, Tooltip } from '@mui/material';
import { ShoppingCart, AccountCircle } from '@mui/icons-material';
import { Button, Avatar } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
  import { setUser, clearUser } from '../redux/slices/userSlice';
  import { setToken } from '../redux/slices/authSlice';
  import { jwtDecode } from "jwt-decode";
  import { db } from './firebase-config';
import { doc,getDoc, onSnapshot, setDoc } from '@firebase/firestore';

  const options = [
    // { label: "Profile", iconClass: "fas fa-user" },
    { label: "Orders", iconClass: "fas fa-shopping-cart" },
    { label: "Logout", iconClass: "fas fa-sign-out-alt" }
    // { label: "Policy", iconClass: "fas fa-sign-out-alt" },
    // { label: "Contact us", iconClass: "fas fa-sign-out-alt" }
  ];
  
const menuItemStyle = {
  display: 'flex',
  alignItems: 'center', // Center icon and text vertically
};

const iconStyle = {
  marginRight: '10px', // Add spacing between icon and text
};
import { useGoogleOneTapLogin } from '@react-oauth/google';


export default function SimpleListMenu() {
    
 
  const navigate=useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);

  const user = useSelector(state => state.user.user);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

const dispatch=useDispatch()
  const handleMenuItemClick = (event, index) => {
    if(index==1){
      dispatch(clearUser())
      // navigate('/login')
    }
    if(index==0){
    navigate('/orders') }
      if(index==3){
    navigate('/return-policy') 
  }
  if(index==4){
    navigate('/contact') 
  }
    setSelectedIndex(index);
    setAnchorEl(null);

  };



  const handleClose = () => {
    setAnchorEl(null);
  };
  const labelStyle = {
    fontSize: '12px', // Adjust the font size as needed
  };

 
  return (
    <>
    {user==null && <Googleloginpage/>}
      {user!=null ? (
        <div style={{paddingLeft:5}}>
        <Tooltip title={user.email}>
            <Avatar      size='small'   onClick={handleClickListItem}  src={user.picture} alt={user.name}
      />
        </Tooltip>
      </div>
    
      ) : (
        <Button
          variant="contained"
          color="primary"
          size='small'
          onClick={()=>{navigate('/login')}}
        
          sx={{ textTransform: 'none' }}
          >
          Sign In
        </Button>
      )}
         {/* <IconButton  sx={{ color: 'black' }}> */}
      
      {/* <Badge badgeContent={1} color="error"> */}
        {/* <AccountCircle /> */}
      {/* </Badge> */}
      {/* <span  style={labelStyle} className='largeDevice'>Account</span> */}
    {/* </IconButton> */}
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.label}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            style={menuItemStyle}
          >
            <i className={option.iconClass} style={iconStyle}></i> {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const Googleloginpage = () => {
  const dispatch = useDispatch();

  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      try {
        const { email, name, picture } = jwtDecode(credentialResponse.credential);

        dispatch(setToken(credentialResponse.credential));
        dispatch(setUser({ email, name, picture }));

        // Check if user already exists
        const userDocRef = doc(db, 'users', email); // Use email as document ID or adjust based on your schema
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          console.log('User already exists, updating...');
          // User exists, you might want to update the document
          await setDoc(userDocRef, { email, name, picture }, { merge: true });
        } else {
          console.log('Creating new user...');
          // User does not exist, create a new document
          await setDoc(userDocRef, { email, name, picture });
        }

        console.log('User operation successful');
      } catch (error) {
        console.error('Error handling Google login:', error);
      }
    },
    onError: () => {
      console.log('Login Failed');
    },
  });

  return <></>;
};