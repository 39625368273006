// src/pages/Contact.js
import React from 'react';
const Contact = () => {
  return (
    <div className="return-policy-container">
      <h1>Contact Policy</h1>

     <ul>
        <li><strong>Email:</strong> <a href="mailto:support@amberspace.co.ke">support@amberspace.co.ke</a></li>
        <li><strong>Phone:</strong> 0101165327</li>
        <li><strong>Address:</strong> Nairobi, Kenya</li>
      </ul>
      
      <p>Thank you for shopping with AmberSpace. We appreciate your business!</p>
    </div>
  );
};

export default Contact;
