import React from 'react';
import Footer from './footer';
import Navbar from './navbar'
const TermsAndConditions = () => {
  return (
    <> 
    
    <Navbar/> <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Amberspace - Terms and Conditions</h1>
      <p><strong>Effective Date:</strong> 1st Jan 2024</p>
      
      <p>Welcome to Amberspace! These Terms and Conditions ("Terms") govern your use of our website and the purchase of electronics from our store. Please read these Terms carefully before using our services or making any purchases.</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>By accessing or using the Amberspace website, you agree to be bound by these Terms. If you do not agree with any part of the Terms, you must not use our website or services.</p>
      
      <h2>2. Changes to Terms</h2>
      <p>Amberspace reserves the right to update or modify these Terms at any time without prior notice. Your continued use of the website constitutes acceptance of any changes.</p>
      
      <h2>3. Eligibility</h2>
      <p>You must be at least 18 years old to use our services or purchase products from Amberspace. By using our services, you confirm that you meet this requirement.</p>
      
      <h2>4. Products and Pricing</h2>
      <ul>
        <li>Amberspace offers a range of electronic products, and prices are displayed on the website.</li>
        <li>Prices are subject to change without notice.</li>
        <li>All products are subject to availability, and we reserve the right to limit the quantity of any product.</li>
      </ul>
      
      <h2>5. Orders and Payments</h2>
      <ul>
        <li>By placing an order, you agree to purchase the product(s) in accordance with these Terms.</li>
        <li>Payment must be made at the time of purchase using M-Pesa. You will receive instructions on how to complete the payment during the checkout process.</li>
        <li>We reserve the right to cancel or refuse any order for any reason, including errors in pricing or product information.</li>
        <li>All payments must be verified before the order is processed and shipped.</li>
      </ul>
      
      <h2>6. Shipping and Delivery</h2>
      <ul>
        <li>Shipping costs and delivery times vary depending on your location. Detailed shipping information will be provided during checkout.</li>
        <li>Amberspace is not responsible for delays caused by third-party shipping services or unforeseen circumstances.</li>
      </ul>
      
      <h2>7. Returns and Refunds</h2>
      <ul>
        <li>We accept returns within 5 days of the purchase date, provided the product is in its original condition and packaging.</li>
        <li>To initiate a return, please contact our customer service team at [insert contact information].</li>
        <li>Refunds will be processed once the returned item is received and inspected. Shipping fees are non-refundable.</li>
      </ul>
      
      <h2>8. Warranty</h2>
      <p>Amberspace provides warranties for certain products as stated on the product pages. Please review each product’s warranty details before purchasing. Warranty claims must be made within the warranty period and must comply with the terms specified.</p>
      
      <h2>9. Limitation of Liability</h2>
      <p>Amberspace is not liable for any indirect, incidental, or consequential damages arising from the use of our website or products, including but not limited to loss of data, revenue, or profits.</p>
      
      <h2>10. Intellectual Property</h2>
      <p>All content on the Amberspace website, including text, graphics, logos, and images, is the property of Amberspace or its content suppliers and is protected by intellectual property laws.</p>
      
      <h2>11. Privacy Policy</h2>
      <p>Please refer to our <a href="/privacy-policy">Privacy Policy</a> for information on how we collect, use, and protect your personal data.</p>
      
      <h2>12. Governing Law</h2>
      <p>These Terms and any disputes arising from your use of Amberspace shall be governed by and construed in accordance with the laws of Kenya.</p>
      
      <h2>13. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <ul>
        {/* <li>Email: [insert email]</li> */}
        <li>Phone: +254101165327</li>
      </ul>
    </div>
        <Footer/>
    </>
  
  );
};

export default TermsAndConditions;
