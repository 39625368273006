import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './navbar';
import BottomTabs from './BottomTabs';

export default function OrderProcessing() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector(state => state.user.user);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`https://payment.amberspace.co/api/orders/all254`, {
        params: { email: user.email }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        const data = await fetchOrders();
        setOrders(data.orders);
      } catch (error) {
        setError('Error fetching orders');
      } finally {
        setLoading(false);
      }
    };

    getOrders();
  }, []);

  const getStatusChip = (status) => {
    let color;
    switch (status.toLowerCase()) {
      case 'pending':
        color = 'warning';
        break;
      case 'in transit':
        color = 'info';
        break;
      case 'failed':
        color = 'error';
        break;
      case 'delivered':
        color = 'success';
        break;
      default:
        color = 'default';
    }
    return <Chip label={status=='failed'?'Not Paid':status} color={color} size="small" />;
  };

  const OrderCard = ({ order }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6">Date: {new Date(order.createdAt).toLocaleDateString()}</Typography>
        <Typography>Total Amount:Ksh {order.totalAmount.toLocaleString()}</Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Status: {getStatusChip(order.paymentStatus)}
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
        {(order.user?.phoneNumber)}
                        <br/>{(order?.user?.email)}
                        <br/>{(order?.user?.contactNumber)}
                        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>Products:</Typography>
        <List dense>
          {order.products.map((product, idx) => (
            <ListItem key={idx}>
              <ListItemText
                primary={`${product.name} (Qty: ${product.quantity})`}
                secondary={`Price:Ksh ${product.price.toLocaleString()}`}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <Typography variant='h4' sx={{ padding: 2 }}>Orders</Typography>
      {loading ? (
        <Typography sx={{ padding: 2 }}>Loading...</Typography>
      ) : error ? (
        <Typography color="error" sx={{ padding: 2 }}>{error}</Typography>
      ) : isSmallScreen ? (
        <div style={{ padding: '0 16px' }}>
          {orders.map((order, index) => (
            <OrderCard key={index} order={order} />
          ))}
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ mx: 2 ,width:'auto'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Client </TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Products</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <TableRow key={index}>
                       <TableCell>{new Date(order.createdAt).toLocaleDateString()}</TableCell> 
                    <TableCell>{(order.user?.phoneNumber)}
                        <br/>{(order.user?.email)}
                        <br/>{(order.user?.contactNumber)}
                    </TableCell>
        
              
                  <TableCell>Ksh {order.totalAmount.toLocaleString()}</TableCell>
                  <TableCell>{getStatusChip(order.paymentStatus)}</TableCell>
                  <TableCell>
                    <List dense>
                      {order.products.map((product, idx) => (
                        <ListItem key={idx}>
                          <ListItemText
                            primary={`${product.name} (Qty: ${product.quantity})`}
                            secondary={`Price: Ksh ${product.price.toLocaleString()}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <BottomTabs />
    </div>
  );
}