// import logo from './logo.svg';
import '../App.css';
import React,{useEffect,useState} from 'react';
import CategoryBanner from '../CategoryBanner';
import NavCart from './navCart'
import MenuItem from './menuItem'
import Search from './search'
import { Typography } from '@mui/material';
import { Rating } from '@mui/material';
import Swiper from '../swiper.js';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import {
    Home as HomeIcon,
    List as ListIcon,
    Person as PersonIcon,
    ShoppingCart as ShoppingCartIcon,
    Chat as ChatIcon,
  } from '@mui/icons-material';
  import {Link, useNavigate } from 'react-router-dom';
function App({setSearch2,search2}) {
    const [productList,setProductList]=useState([]);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        // Get the current scroll position
        const currentPosition = window.scrollY;
  
        // Update the scroll position state
        setScrollPosition(currentPosition);
  
        // Check if the scroll position is above 50 pixels
        if (currentPosition > 50) {
          // Scroll position is above 50 pixels
          console.log('Scroll position is above 50 pixels.');
        } else {
          // Scroll position is not above 50 pixels
          console.log('Scroll position is not above 50 pixels.');
        }
      };
  
      // Add an event listener to the window's scroll event
      window.addEventListener('scroll', handleScroll);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    useState(()=>{
 fetch('https://fakestoreapi.com/products')
            .then(res=>res.json())
            .then(json=>{
                setProductList(json)
                // console.log(json)
              })
    },[])
const navigate=useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [search,setSearch]=useState(false)
    useEffect(() => {
      const handleResize = () => {
        // Update the screen dimensions when the window is resized
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
      };
  
      // Add an event listener to the window's resize event
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <div >
{/* style={{top:0,width:'100%',background:"white",zIndex:100}} */}
     {scrollPosition<100 && <div className='mynav' >
    <div className='logoDiv'>
<div >
    
    <font onClick={()=>{navigate('/')}} className="" style={{display:'flex',alignItems:'center',userSelect:'none'}}> 
    <img src='/assets/images/logo.png' height='40px'/> 
       Amberspace </font>
    </div >
    {/* <div style={{height:50,textAlign:'center',color:'white',width:50,borderRadius:25,backgroundColor:'#282625'}}>

    </div> */}
    <div style={{flex:1}}></div>
    <div>
  <NavCart />
    </div>
    </div>
    <div style={{height:1,marginBottom:4,marginTop:4,opacity:0.5,backgroundColor:'#e5e5e5'}}></div>
   
<div style={{width:20}}></div>
    <div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:'center'}}>
    <MenuItem/>
<Search search2={search2} setSearch2={setSearch2}/>
{/* <input type='text' className='searchBox' style={{borderRadius:'30px',padding:5,outline:'none',border:0}} placeholder='Search products'/> */}
{/* <button className='searchButton'>Search</button> */}
</div>
<div>
{/* icons */}
</div>
{/* test run */}
    </div>}
    {scrollPosition>=100 && <div className='mynav' style={{top:0,background:"white",zIndex:100,position:'fixed',left:0,right:0}}>
    <div className='logoDiv'>
<div >
    
{screenWidth>600 && <font className=" mediumDevice" style={{cursor:'pointer',userSelect:'none',display:'flex',alignItems:'center'}}>
     <img src='/assets/images/logo.png' height='40px'/>  Amberspace </font>}
    </div >
    {/* <div style={{height:50,textAlign:'center',color:'white',width:50,borderRadius:25,backgroundColor:'#282625'}}>

    </div> */}
    <div style={{flex:1}}>
    <div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:search  ?'center': screenWidth>600?'center':'start'}}>
    <MenuItem setSearch={setSearch}/>
    {screenWidth<600 &&
    !search ? <font className="" style={{display:'flex',alignItems:'center',userSelect:'none',userSelect:'none'}}>
       <img src='/assets/images/logo.png' height='40px'/> 
       Amberspace </font>: <Search search2={search2} setSearch2={setSearch2} />}
       {!search && screenWidth<600 &&  <div style={{flex:1}}></div>}
       {!search && screenWidth<600 && <IconButton
      color="black"
      style={{background: 'linear-gradient(45deg,  #FF8C00, #FF5733)',}}
      aria-label="Search"
      onClick={()=>{setSearch(true)}}
    >
      <SearchIcon style={{ color: 'white'}} search={setSearch} />
    </IconButton>}
{/* <input type='text' className='searchBox' style={{borderRadius:'30px',padding:5,outline:'none',border:0}} placeholder='Search products'/> */}
{/* <button className='searchButton'>Search</button> */}
</div>
    </div>
    <div>
  <NavCart/>
    </div>
    </div>
  
<div>
{/* icons */}
</div>
{/* test run */}
    </div>}
{scrollPosition>=100 && <div style={{height:100}}></div>}
    
 
    </div>
   
  );
}

export default App;
