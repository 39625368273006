import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, Badge, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../redux/features/cart/cartSlice';
import { Link } from 'react-router-dom';

const labelStyle = {
  fontSize: '14px',
  color: 'black',
};

export default function Cart() {
  const [anchorEl, setAnchorEl] = useState(null);
  const items = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-KE', { style: 'currency', currency: 'KES' }).format(price);
  };

  const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <>
      <IconButton onClick={handleClick} sx={{ color: 'black' }}>
        <Badge badgeContent={items.length} color="error">
          <ShoppingCartIcon />
        </Badge>
        <span style={labelStyle} className="largeDevice">Cart</span>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '300px',
            maxHeight: '500px',
          },
        }}
      >
        {items.length === 0 ? (
          <Typography style={{ width: '100%', textAlign: 'center', margin: 10, userSelect: 'none' }}>
            No items in cart
          </Typography>
        ) : (
          <>
            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
              {items.map((item, index) => (
                <MenuItem key={index}>
                  <div style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
                    <img src={item.image} alt={item.name} style={{ marginRight: '10px', width: '50px', height: '50px' }} />
                    <div style={{ flex: 1 }}>
                      <div style={{ whiteSpace: 'pre-wrap' }}>
                        <Typography variant="body2" style={{ fontSize: 12 }}>
                          {item.name}
                        </Typography>
                  
                    <Typography  variant="body2" style={{ fontSize: 12,fontWeight:'bold' }}>
                      {formatPrice(item.price)} x {item.quantity}
                    </Typography>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => dispatch(decrementQuantity({ productId: item.productId }))}>
                          <RemoveIcon />
                        </IconButton>
                        <span style={{ margin: '0 10px' }}>{item.quantity}</span>
                        <IconButton onClick={() => dispatch(incrementQuantity({ productId: item.productId }))}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div>
                    <IconButton onClick={() => dispatch(removeFromCart({ productId: item.productId }))}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </MenuItem>
              ))}
            </div>
            <div style={{ padding: 5 }}>
              <Typography variant="h6" style={{ textAlign: 'right' ,fontWeight:'bold'}}>
                Total: {formatPrice(total)}
              </Typography>
              </div>
            <div style={{ padding: 5 }}>
              <Link to={'../cart'}>
               <Button
                variant="contained"
                fullWidth
                sx={{
                  background: 'linear-gradient(45deg, #405de6 30%, #5851db 90%)',
                  color: 'white',
                  marginTop: '16px',
                }}
                size="small"
                onClick={handleClose}
              >
                Go to Checkout
              </Button>
              </Link>
             
            </div>
          </>
        )}
      </Menu>
    </>
  );
}
