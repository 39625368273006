// app/rootReducer.js

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './features/user/userSlice';
import cartReducer from './features/cart/cartSlice';
import authReducer from './slices/authSlice';
// import userReducer from './slices/userSlice';
const cartPersistConfig = {
  key: 'cart',
  storage,
};

const userPersistConfig = {
  key: 'user',
  storage,
};
const authPersistConfig = {
  key: 'auth',
  storage,
};


const rootReducer = combineReducers({
  cart: persistReducer(cartPersistConfig, cartReducer),
  user: persistReducer(userPersistConfig, userReducer),
  auth: persistReducer(authPersistConfig, authReducer)
});

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'user','auth']
};

export default persistReducer(rootPersistConfig, rootReducer);
