import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore"
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAJ1rhGa4OaFsPRE8oSXoQKYLtEH9V9yF0",
  authDomain: "carspace-950a4.firebaseapp.com",
  projectId: "carspace-950a4",
  storageBucket: "carspace-950a4.appspot.com",
  messagingSenderId: "391205113341",
  appId: "1:391205113341:web:5b5d587ecdd86193439d94",
  measurementId: "G-EP8XM2FRMR"
};

const app=initializeApp(firebaseConfig);
export const db=getFirestore(app);
export const storage = getStorage(app);
