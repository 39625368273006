// import logo from './logo.svg';
import './App.css';
import React,{useEffect,useState} from 'react';
// import { GoogleOAuthProvider } from '@react-oauth/google';
  import { BrowserRouter,Routes,Route,Link, useNavigate } from 'react-router-dom';
  import Product from './components/products'
  import Home from './components/home'
  import Admin from './components/admin'
import Category  from './components/category'
import {
  Home as HomeIcon,
  List as ListIcon,
  Person as PersonIcon,
  ShoppingCart as ShoppingCartIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Cart from './components/cart';
import OrdersPage from './components/orders';
import LoginPage from './components/Login';
import Orders from './components/orderView';
import PaymentStatus from './components/paymentStatus';
import OrderProcessing from './components/orderProcessing';
import ReturnPolicyPage from './components/returnPolicy';
import Contact from './components/about';
import PrivacyPolicy from './components/privacy';
import TermsAndConditions from './components/terms';
import FAQ from './components/faq';
function App() {

  return (
    
        
<BrowserRouter>
<Routes>
  
  <Route path='/' element={<Home/>}  />
  <Route  path="/login" element={<LoginPage/>} />
  <Route  path="/orders" element={<Orders/>} />
  <Route path='/product/:id/:slug' element={<Product/>}  />
  <Route path='/category' element={<Category/>}  />
  <Route path='/cart' element={<Cart/>}  />
  <Route path='/admin/254' element={<Admin/>}  />
  <Route path='/orders' element={<OrdersPage/>}  />
  <Route path='/payment' element={<PaymentStatus/>}/>
  <Route path='/order/processing' element={<OrderProcessing/>}/>
  <Route path='/return-policy' element={<ReturnPolicyPage/>}/>
  <Route path='/contact' element={<Contact/>}/>
  <Route path='/terms' element={<TermsAndConditions/>}/>
  <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
  <Route path='/faq' element={<FAQ/>}/>
  <Route path='*' element={<Home />} />
</Routes>

</BrowserRouter>
   
  );
}

export default App;
