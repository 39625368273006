import React from 'react';
import { Container, Typography, Box } from '@mui/material';

import Footer from './footer';
import Navbar from './navbar'
const PrivacyPolicy = () => {
  return (

    <>
    <Navbar/>
      <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          AmberSpace Privacy Policy
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography paragraph>
          Welcome to AmberSpace. We are committed to protecting your personal information and your right to privacy. This Privacy Policy describes how we collect, use, and share your personal information when you use our website and services.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography paragraph>
          We collect personal information that you provide to us such as:
        </Typography>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Billing and shipping address</li>
          <li>Payment information</li>
          <li>Purchase history</li>
        </ul>
        <Typography paragraph>
          We also automatically collect certain information when you visit our website, including:
        </Typography>
        <ul>
          <li>IP address</li>
          <li>Browser type</li>
          <li>Device information</li>
          <li>Cookies and usage data</li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use your personal information to:
        </Typography>
        <ul>
          <li>Process and fulfill your orders</li>
          <li>Communicate with you about your orders and our services</li>
          <li>Improve our website and services</li>
          <li>Send you marketing communications (with your consent)</li>
          <li>Detect and prevent fraud</li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          4. How We Share Your Information
        </Typography>
        <Typography paragraph>
          We may share your personal information with:
        </Typography>
        <ul>
          <li>Service providers who help us operate our business</li>
          <li>Payment processors to process your payments</li>
          <li>Shipping companies to deliver your orders</li>
          <li>Law enforcement agencies when required by law</li>
        </ul>
        <Typography paragraph>
          We do not sell your personal information to third parties.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography paragraph>
          Depending on your location, you may have the right to:
        </Typography>
        <ul>
          <li>Access the personal information we hold about you</li>
          <li>Correct inaccurate information</li>
          <li>Delete your personal information</li>
          <li>Object to our use of your information</li>
          <li>Withdraw consent for marketing communications</li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          6. Data Security
        </Typography>
        <Typography paragraph>
          We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Children's Privacy
        </Typography>
        <Typography paragraph>
          Our services are not intended for children under 13. We do not knowingly collect personal information from children under 13.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          8. Changes to This Policy
        </Typography>
        <Typography paragraph>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about this Privacy Policy, please contact us at:
        </Typography>
        <Typography>
          AmberSpace<br />
          Nairobi, Kenya<br />
          {/* Email: [Your Contact Email]<br /> */}
          Phone: +254101165327
        </Typography>
      </Box>
    </Container>
    <Footer/>
    </>
  
  );
};

export default PrivacyPolicy;