import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { 
  Typography, 
  Table, 
  TableBody, Button,
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Card,
  CardContent,
  List,
  ListItem,Modal, Box,  TextField,
  ListItemText,
  useMediaQuery,
  Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './navbar';
import BottomTabs from './BottomTabs';

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector(state => state.user.user);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`https://payment.amberspace.co/api/orders`, {
        params: { email: user.email }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };
const makePayment=async (items)=>{
  console.log(items)
  try{

    const products = items.products.map(t => ({
      productId: t.productId,
      quantity: t.quantity
    }));

    const response = await axios.post(
      `https://payment.amberspace.co/api/order-payment`,
      { 'orderId':items.orderId, products,phoneNumber}
    );
    console.log(response.data);
    handleOpenModal()
    // Show success toast
    toast.success('Check Phone for payment!', {
      position: 'top-right',
    });
  }catch(e){
console.log(e)
  }
}
const [modalOpen, setModalOpen] = useState(false);

const handleOpenModal = () => setModalOpen(true);
const handleCloseModal = () => setModalOpen(false);
const getOrders = async () => {
  try {
    const data = await fetchOrders();
    setOrders(data.orders);
  } catch (error) {
    setError('Error fetching orders');
  } finally {
    setLoading(false);
  }
};
const handlePaymentCompleted = () => {
  handleCloseModal()
  
  getOrders()
};

  useEffect(() => {
  

    getOrders();
  }, []);

  const getStatusChip = (status) => {
    let color;
    switch (status.toLowerCase()) {
      case 'pending':
        color = 'warning';
        break;
      case 'in transit':
        color = 'info';
        break;
      case 'failed':
        color = 'error';
        break;
      case 'delivered':
        color = 'success';
        break;
      default:
        color = 'default';
    }
    return <Chip label={status=='failed'?'Not Paid':status} color={color} size="small" />;
  };
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleSubmit = () => {
    if(phoneNumber.length<9){
      alert('please enter the correct phone number')
    }
      // Perform action on submit (e.g., save phone number)
      console.log("Phone number entered:", phoneNumber);
      handleClose();
    makePayment(selected)
  }

  const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
  };
const [selected,setSelected]=useState({})

  const OrderCard = ({ order }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6">Date: {new Date(order.createdAt).toLocaleDateString()}</Typography>
        <Typography>Total Amount:Ksh {order.totalAmount.toLocaleString()}</Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Status: {getStatusChip(order.paymentStatus)}
        </Typography>
        {order.paymentStatus=='failed' && <Button variant={'outlined'} style={{margin:3}} onClick={()=>{handleOpen(order);setSelected(order)}} >Pay</Button>}
        <Typography variant="subtitle1" sx={{ mt: 1 }}>Products:</Typography>
        <List dense>
          {order.products.map((product, idx) => (
            <ListItem key={idx}>
              <ListItemText
                primary={`${product.name} (Qty: ${product.quantity})`}
                secondary={`Price:Ksh ${product.price.toLocaleString()}`}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <Typography variant='h4' sx={{ padding: 2 }}>Orders</Typography>
      {loading ? (
        <Typography sx={{ padding: 2 }}>Loading...</Typography>
      ) : error ? (
        <Typography color="error" sx={{ padding: 2 }}>{error}</Typography>
      ) : isSmallScreen ? (
        <div style={{ padding: '0 16px' }}>
          {orders.map((order, index) => (
            <OrderCard key={index} order={order} />
          ))}
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ mx: 2 ,width:'auto'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Products</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <TableRow key={index}>
                  <TableCell>{new Date(order.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>{order.totalAmount.toLocaleString()}</TableCell>
                  <TableCell>{getStatusChip(order.paymentStatus)}
                 
                    {order.paymentStatus=='failed' && <Button variant={'outlined'} style={{margin:3}} onClick={()=>{handleOpen(order);setSelected(order)}} >Pay</Button>}
                  </TableCell>
                  <TableCell>
                    <List dense>
                      {order.products.map((product, idx) => (
                        <ListItem key={idx}>
                          <ListItemText
                            primary={`${product.name} (Qty: ${product.quantity})`}
                            secondary={`Price: Ksh ${product.price.toLocaleString()}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <BottomTabs />
      <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-phone-number-title"
              aria-describedby="modal-phone-number-description"
          >
              <Box sx={style}>
                  <Typography id="modal-phone-number-title" variant="h6" component="h2">
                      Enter your <b>Mpesa</b> Phone Number
                  </Typography>
                  <TextField
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                      Submit
                  </Button>
              </Box>
          </Modal>

          <PaymentNotificationModal
                open={modalOpen}
                onClose={handleCloseModal}
                onPaymentCompleted={handlePaymentCompleted}
                message="We have initiated your payment. Please check your phone for confirmation."
            />
    </div>
  );
}


const PaymentNotificationModal = ({ open, onClose, onPaymentCompleted, message }) => {

  const handlePaymentCompleted = () => {
      // Call the function passed via props when payment is completed
      onPaymentCompleted();
      onClose();
  };

  const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
  };

  return (
      <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="payment-notification-title"
          aria-describedby="payment-notification-description"
      >
          <Box sx={style}>
              <Typography id="payment-notification-title" variant="h6" component="h2">
                  Check Your Phone
              </Typography>
              <Typography id="payment-notification-description" sx={{ mt: 2 }}>
                  {message ? message : 'Please check your phone to complete the payment.'}
              </Typography>
              <Button
                  variant="contained"
                  color="success"
                  onClick={handlePaymentCompleted}
                  sx={{ mt: 3 }}
              >
                  Payment Completed
              </Button>
          </Box>
      </Modal>
  );
};
