import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {Typography,Menu} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../redux/features/cart/cartSlice';
import { doc, setDoc, addDoc, collection } from '@firebase/firestore';
import { db } from './firebase-config';
import BottomTabs from './BottomTabs';
import Navbar from './navbar.js';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { clearCart } from '../redux/features/cart/cartSlice.js';
import { setUser, clearUser } from '../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
export default function Cart() {
  
  const user = useSelector(state => state.user.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contact, setContact] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');

  const items = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const navigate=useNavigate()

 const handleOpenModal = () => {
    if(user==null){
      navigate('/login')
      return;
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [loading,setLoading]=useState(false)
  const handleCheckout = async () => {
    if(phoneNumber.trim()==''){

      toast.error('Please enter payment number', {
        position: 'top-right',
      });
      return;
    }

    try {
      setLoading(true)
      const email = user.email; 
      const products = items.map(t => ({
        productId: t.productId,
        quantity: t.quantity
      }));
  
      const response = await axios.post(
        `https://payment.amberspace.co/api/create-order`,
        { 'user':{phoneNumber,email,city,address,"contactNumber":contact}, products}
      );
  
      // Log response data
      console.log(response.data);
  
      // Show success toast
      toast.success('Order submitted successfully!', {
        position: 'top-right',
      });
     dispatch(clearCart());
      setIsModalOpen(false);
      setLoading(false)
      // Handle success, e.g., clear the cart or redirect
    } catch (error) {
      // Log error and show error toast
      console.error('Error submitting order:', error);
      toast.error('Error submitting order. Please try again later.', {
        position: 'top-right',
      });
      setLoading(false)
    }
  
      // console.log('Order submitted successfully:', response.data);
      // const orderData = {
      //   customerName,
      //   phoneNumber,
      //   location,
      //   timestamp: new Date(),
      //   items: items.map(item => ({ name: item.name, quantity: item.quantity, price: item.price })),
      // };

      // const docRef = await addDoc(collection(db, 'orders'), orderData);

      // toast.success('Order submitted successfully!', {
      //   position: 'top-right',
      // });

      // dispatch(clearCart());
      // setIsModalOpen(false);
 
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-KE', { style: 'currency', currency: 'KES' }).format(price);
  };

  const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <div style={{ width: '100%', maxWidth: '500px' }}>
          <Typography variant="h5" style={{ padding: 10, textAlign: 'center' }}>Cart</Typography>
          {items.length === 0 && (
            <Typography style={{ textAlign: 'center', margin: 10, userSelect: 'none' }}>
              No items in cart
            </Typography>
          )} 
          {items.map((item, index) => (

            <MenuItem key={index} style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
                <img
                  src={item.image}
                  alt={item.name}
                  style={{ marginRight: '10px', width: '50px', height: '50px' }}
                />
                <div style={{ flex: 1 }}>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    <Typography variant="body2" style={{ fontSize: 12 }}>
                      {item.name}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 12 }}>
                      {formatPrice(item.price)} x {item.quantity}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => dispatch(decrementQuantity({ productId: item.productId }))}>
                      <RemoveIcon />
                    </IconButton>
                    <span style={{ margin: '0 10px' }}>{item.quantity}</span>
                    <IconButton onClick={() => dispatch(incrementQuantity({ productId: item.productId }))}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
                <IconButton onClick={() => dispatch(removeFromCart({ productId: item.productId }))}>
                  <CloseIcon />
                </IconButton>
              </div>
            </MenuItem>
          ))}
          {items.length > 0 && (
            <div style={{ padding: 5 }}>
              <Typography variant="h6" style={{ textAlign: 'right' ,fontWeight:'bold'}}>
                Total: {formatPrice(total)}
              </Typography>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  background: 'linear-gradient(45deg, #405de6 30%, #5851db 90%)',
                  color: 'white',
                  marginTop: '16px',
                }}
                onClick={handleOpenModal}
              >
                Checkout
              </Button>
            </div>
          )}
          <div style={{ height: 150 }}></div>
        </div>
      </div>
      <BottomTabs />

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            minWidth: '300px',
            maxWidth: '500px',
            borderRadius: '8px',
            outline: 'none',
          }}
        >
          <Typography variant="h6" id="modal-title" style={{ marginBottom: '10px' }}>
            Enter Your Details
          </Typography>
          {/* <TextField
            label="Name"
            fullWidth
            variant="outlined"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            style={{ marginBottom: '10px' }}
          /> */}
        
        
          <small>Please  enter address fo your delivery address and Contact phone number</small>
             <TextField
            label="City"
            fullWidth
            variant="outlined"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
            <TextField
            multiline
        
            label="Address Description"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            style={{ marginBottom: '10px'}}
          />
              <TextField
            multiline
        
            label="Contact Phone Number"
            fullWidth
            variant="outlined"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            style={{ marginBottom: '10px'}}
          />
          
          <small>Please enter your <b>Mpesa payment </b> number</small>
              <TextField
            label="Mpesa Phone No"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <div style={{display:'flex',justifyContent:"space-between",alignItems:'center'}}> 
<img height='50px' src='https://amref.org/wp-content/uploads/2017/10/lipa-na-mpesa-paybill-numbers-for-banks.fw_.png'/>
            <Typography variant="h6" style={{ textAlign: 'right' ,fontWeight:'bold'}}>
                Total: {formatPrice(total)}
              </Typography>
          </div>
          
          <Button
            variant="contained"
            onClick={handleCheckout}
            style={{
              background: 'linear-gradient(45deg, #405de6 30%, #5851db 90%)',
              color: 'white',
              marginTop: '16px',
              width: '100%',
            }}
            disabled={loading}
          >
            Confirm Checkout
          </Button>
        </div>
      </Modal>
    </div>
  );
}
