import React,{useEffect,useState} from 'react';

 import {  useNavigate } from 'react-router-dom';

import {
  Home as HomeIcon,
  List as ListIcon,
  Person as PersonIcon,
  ShoppingCart as ShoppingCartIcon,
  Chat as ChatIcon,
  ListAlt,
  Inventory,
  Assignment,
  Receipt,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
export default function BottomTabs() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
   const navigate=useNavigate()
      useEffect(() => {
        const handleResize = () => {
          // Update the screen dimensions when the window is resized
          setScreenWidth(window.innerWidth);
          setScreenHeight(window.innerHeight);
        };
    
        // Add an event listener to the window's resize event
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return (
    <>
    {screenWidth<600 &&  <div style={{backgroundColor:'white',height:50,position:'fixed',bottom:0,left:0,width:'100%',zIndex:100,boxShadow: '0px -2px 2px 0px rgba(0,0,0,0.4)',display:'flex',justifyContent:'space-around'}}>
<div style={{flex:1,height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>

 <IconButton onClick={()=>navigate('/')}
        style={{
          margin: '0 15px',
          color:window.location.pathname=='/'?'white':'black',
          background:window.location.pathname=='/'? 'linear-gradient(45deg, #FF8C00, #FF5733)':'white',
        }}
    >
        <HomeIcon fontSize="medium" /> {/* Home icon */}
      </IconButton>
    </div>

    <div style={{flex:1,height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
  <IconButton onClick={()=>navigate('/category')}
        style={{ margin: '0 15px',
        color:window.location.pathname=='/category'?'white':'black',
        background:window.location.pathname=='/category'? 'linear-gradient(45deg, #FF8C00, #FF5733)':'white',
   
      }}
      >
        <ListIcon fontSize="medium" /> {/* List icon with gradient background */}
      </IconButton>
    </div>
    <div style={{flex:1,height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <IconButton onClick={()=>navigate('/cart')} style={{ margin: '0 15px',
         color:window.location.pathname=='/cart'?'white':'black',
         background:window.location.pathname=='/cart'? 'linear-gradient(45deg, #FF8C00, #FF5733)':'white',
    
   
    }}>
        <ShoppingCartIcon fontSize="medium" /> {/* Shopping cart icon */}
      </IconButton>
</div>

<div style={{flex:1,height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>

<IconButton onClick={()=>navigate('/orders')} style={{ margin: '0 15px',
         color:window.location.pathname=='/orders'?'white':'black',
         background:window.location.pathname=='/orders'? 'linear-gradient(45deg, #FF8C00, #FF5733)':'white',
    
   
    }}>
        <Receipt fontSize="medium" /> {/* Chat icon */}
      </IconButton>
</div>
     
    
  
    </div>}
    </>
  )
}
