import React, { useEffect } from 'react'
import Navbar from './navbar';
import { ToastContainer,toast } from 'react-toastify';
import { Button, Chip, Typography } from '@mui/material';
export default function PaymentStatus() {
    useEffect(()=>{
        toast.success('Order submitted successfully!', {
            position: 'top-right',
          });
    },[])
  return (
    <>
    
    <ToastContainer />
    <Navbar />
       <div style={{display:'flex',backgroundColor:'#f8f8f8',gap:10,textAlign:'center',marginTop:100,paddingTop:40,paddingBottom:40,flexDirection:"column",alignItems:"center",justifyContent:'center'}}>
        <img  style={{width:80}} src='https://cdn3.iconfinder.com/data/icons/basicolor-arrows-checks/24/155_check_ok_sticker_success-512.png'/>

 Success
        <Typography variant='h6'>
            Your Order Was Made Successfully,<br/> Please check your Phone for mpesa payment
        </Typography>
        {/* <Typography variant='h6'>
         </Typography> */}
        <Chip label="Pending payment" />
        <Button variant='contained' color="primary">Payment Completed </Button>
        </div>
    </>
 
  )
}
