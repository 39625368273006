import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from './firebase-config'; // Import your Firestore instance
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination'; // Material-UI Pagination component
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showUndeliveredOnly, setShowUndeliveredOnly] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5); // Number of orders per page

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'orders'));
        const fetchedOrders = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        // Sort orders by timestamp in descending order
        fetchedOrders.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
        setOrders(fetchedOrders);
        filterOrders(fetchedOrders); // Initialize filtered orders with all orders
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    filterOrders(orders, value, selectedDate, selectedStatus, showUndeliveredOnly);
  };

  // Handle date selection from date picker
  const handleDateChange = (date) => {
    setSelectedDate(date);
    filterOrders(orders, searchQuery, date, selectedStatus, showUndeliveredOnly);
  };

  // Handle status change
  const handleStatusChange = async (orderId, status) => {
    try {
      const orderRef = doc(db, 'orders', orderId);
      await updateDoc(orderRef, { status });
      // Update state to reflect changes immediately
      const updatedOrders = orders.map(order => 
        order.id === orderId ? { ...order, status } : order
      );
      setOrders(updatedOrders);
      filterOrders(updatedOrders, searchQuery, selectedDate, selectedStatus, showUndeliveredOnly);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  // Handle filter change for status
  const handleStatusFilterChange = (e) => {
    setSelectedStatus(e.target.value);
    filterOrders(orders, searchQuery, selectedDate, e.target.value, showUndeliveredOnly);
  };

  // Handle filter change for undelivered orders
  const handleFilterChange = (e) => {
    setShowUndeliveredOnly(e.target.checked);
    filterOrders(orders, searchQuery, selectedDate, selectedStatus, e.target.checked);
  };

  // Filter orders based on search query, date, status, and delivery status
  const filterOrders = (ordersList, query, date, statusFilter, undeliveredOnly) => {
    let filtered = ordersList;

    if (query) {
      const regex = new RegExp(query, 'i'); // Case-insensitive regex
      filtered = filtered.filter(order =>
        regex.test(order?.customerName) ||
        regex.test(order?.phoneNumber) ||
        regex.test(order?.location) ||
        regex.test(order?.items.map(item => item.name).join(' '))
      );
    }

    if (date) {
      filtered = filtered.filter(order => {
        const orderDate = order?.timestamp?.toDate(); // Convert Firestore timestamp to Date object
        return orderDate && orderDate.toDateString() === date.toDateString(); // Ensure orderDate is not null before calling toDateString()
      });
    }

    if (statusFilter) {
      filtered = filtered.filter(order => order.status === statusFilter);
    }

    if (undeliveredOnly) {
      filtered = filtered.filter(order => order.status !== 'Delivered');
    }

    setFilteredOrders(filtered);
  };

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Typography variant="h5" style={{ textAlign: 'center', margin: '20px' }}>
        Orders
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <TextField
          label="Search by Name, Phone Number, Location or Item"
          variant="outlined"
          value={searchQuery}
          autoComplete='off'
          onChange={handleSearch}
          style={{ width: '300px', marginRight: '20px' }}
        />
        {/* Uncomment the following lines if you want to use the date picker */}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Search by Date"
            inputVariant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            style={{ width: '300px' }}
          />
        </LocalizationProvider> */}
        <FormControl variant="outlined" style={{ width: '200px', marginRight: '20px' }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={selectedStatus}
            onChange={handleStatusFilterChange}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="In Transit">In Transit</MenuItem>
            <MenuItem value="Canceled">Canceled</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
          </Select>
        </FormControl>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={showUndeliveredOnly}
              onChange={handleFilterChange}
            />
          }
          label="Show Undelivered Only"
        /> */}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <TableContainer component={Paper} style={{ maxWidth: '1000px', width: '100%', padding: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date and Time</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentOrders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: 'center' }}>No orders found.</TableCell>
                </TableRow>
              ) : (
                currentOrders.map(order => (
                  <TableRow key={order?.id}>
                    <TableCell>{order?.timestamp?.toDate().toLocaleString()}</TableCell>
                    <TableCell>{order?.customerName}</TableCell>
                    <TableCell>{order?.phoneNumber}</TableCell>
                    <TableCell>{order?.location}</TableCell>
                    <TableCell>
                      <ul>
                        {order?.items.map((item, index) => (
                          <li key={index}>{item.name} - Quantity: {item.quantity}</li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          value={order.status || 'Pending'}
                          onChange={(e) => handleStatusChange(order.id, e.target.value)}
                          label="Status"
                        >
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="In Transit">In Transit</MenuItem>
                          <MenuItem value="Canceled">Canceled</MenuItem>
                          <MenuItem value="Delivered">Delivered</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Pagination
          count={Math.ceil(filteredOrders.length / ordersPerPage)}
          page={currentPage}
          onChange={(event, page) => paginate(page)}
          color="primary"
        />
      </div>
    </div>
  );
}
